<template>
  <div class="user-account" :class="type" data-t="user-account">
    <div class="header-panel" @click.stop>
      <span class="header-panel-title">
        {{ t('payments.wallet.myAssets') }}
      </span>
      <div class="header-panel-toggle">
        <StToggle v-model="hideNulls" size="s" data-t="header-panel-toggle" />
        <span class="header-panel-subtitle">{{
          t('payments.wallet.hideNulls')
        }}</span>
      </div>
    </div>
    <div
      v-if="
        !hideHeader &&
        (type === 'default' || type === 'mobile') &&
        filteredRealAccounts.length
      "
      class="curency-header"
    >
      <div class="wrapper-left">
        <div class="header-active">
          {{ t('payments.account.headerActive') }}
        </div>
      </div>
      <div class="wrapper-right">
        <div class="header-balance">
          {{ t('payments.account.headerBalance') }}
        </div>
        <div v-if="type === 'default'" class="space-right"></div>
      </div>
    </div>
    <div v-if="!filteredRealAccounts.length" class="empty-accounts">
      <img :src="emptyAccounts" class="empty-image" alt="empty" />
      <span class="empty-accounts-text">
        {{ t('payments.wallet.hiddenAccounts') }}
      </span>
    </div>
    <div
      v-for="account in filteredRealAccounts"
      v-else
      :key="account.id"
      class="currency"
      data-t="currency"
      @click="updateActiveAccount(account.id)"
    >
      <div class="wrapper-left">
        <div
          class="radio"
          :class="{ active: account.code === activeAccount?.code }"
        />
        <div class="currency-logo">
          <StIcon
            :name="account.icon"
            :size="type === 'small' ? 24 : 32"
            class="icon"
          />
        </div>
        <div class="currency-content">
          <div class="currency-name">
            <div class="short-name" data-t="short-name">{{ account.code }}</div>
            <div class="full-name" data-t="full-name">{{ account.name }}</div>
          </div>
        </div>
      </div>
      <div class="wrapper-right">
        <div class="balance">
          <div class="balance-crypto" data-t="balance-crypto">
            {{ formatCrypto(account.balance) }}
          </div>
          <BonusBalance
            v-if="+account.bonusAccountAmount > 0"
            :amount="account.bonusAccountAmount"
          />
          <div v-else class="balance-fiat" data-t="balance-fiat">
            ~{{ formatCurrency(account.fiatBalance) }}
          </div>
        </div>
        <div v-if="type === 'default'" class="balance-actions">
          <div
            v-if="account.code === activeAccount?.code"
            class="active-button"
            data-t="active-account"
          >
            {{ t('payments.account.active') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAccountsStore } from '../../stores/useAccountsStore'
import { useCurrenciesStore } from '../../stores/useCurrenciesStore'
import emptyAccounts from './parts/empty.svg'
import BonusBalance from '../BonusBalance/BonusBalance.vue'

interface Props {
  type: 'default' | 'small' | 'mobile'
  hideHeader?: boolean
}

withDefaults(defineProps<Props>(), { type: 'default' })

const accountStore = useAccountsStore()
const { updateActiveAccount } = accountStore
const { filteredRealAccounts, activeAccount, hideNulls } =
  storeToRefs(accountStore)

const currenciesStore = useCurrenciesStore()
const { appCurrency } = storeToRefs(currenciesStore)

const { t } = useI18n()
const { format: formatCrypto } = useCryptoFormatter()
const { format: formatCurrency } = useCurrencyFormatter({
  currency: appCurrency.value.code,
})
</script>

<style scoped>
.user-account {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
}

.radio {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--spacing-200);
  height: var(--spacing-200);

  border: 1.5px solid var(--border-primary);
  border-radius: var(--border-radius-full);

  transition: all 0.2s ease;

  &.active {
    border-color: var(--text-link);
  }

  &.active::after {
    content: '';

    width: 10px;
    height: 10px;

    background-color: var(--text-link);
    border-radius: var(--border-radius-full);
  }
}

.currency {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 56px;
  padding-left: var(--spacing-200);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);

  transition: all 0.2s ease;

  &:hover {
    background-color: var(--background-tertiary);

    .radio {
      border-color: var(--text-link);
    }
  }
}

.currency-logo {
  line-height: 0;
}

.currency-content {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: var(--border-radius-050);
}

.currency-name {
  .short-name {
    font: var(--desktop-text-md-semibold);
  }

  .full-name {
    font: var(--desktop-text-xs-medium);
    color: var(--palette-light-600);
  }
}

.balance {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .balance-crypto {
    font: var(--desktop-text-md-semibold);
  }

  .balance-fiat {
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
}

.balance-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 121px;

  /* stylelint-disable */
  padding-right: 18px;

  .active-button {
    padding: var(--spacing-025) var(--spacing-100);

    font: var(--desktop-text-xs-medium);
    text-align: center;

    background-color: var(--button-primary-default);
    border-radius: var(--border-radius-075);
  }
}

.wrapper-left,
.wrapper-right {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
  height: 100%;
}

.curency-header {
  display: flex;
  justify-content: space-between;

  min-height: 16px;
  padding: var(--spacing-075) 0;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);

  .header-active {
    padding-left: var(--spacing-600);
  }

  .space-right {
    min-width: 121px;
  }
}

.user-account.small {
  max-width: 360px;

  .wrapper-left,
  .wrapper-right {
    gap: var(--spacing-150);
  }

  .currency {
    min-height: 48px;
    padding: var(--spacing-050) var(--spacing-150);

    &:hover {
      border-radius: var(--border-radius-075);
    }
  }
}

.user-account.mobile {
  gap: 0;
  background-color: transparent;

  .curency-header {
    margin: 0;

    .wrapper-right {
      margin-right: var(--spacing-200);
      font: var(--mobile-caption-1-medium);
    }
  }

  .currency {
    position: relative;
    min-height: 52px;
    margin-right: var(--spacing-200);
    background-color: transparent;

    &::after {
      content: '';

      position: absolute;
      right: calc(-1 * var(--spacing-200));
      bottom: 0;

      width: calc(100% - var(--spacing-400));

      border-bottom: var(--border-width-medium) solid var(--border-secondary);
    }
  }

  .currency-content {
    margin-left: calc(-1 * var(--spacing-050));
  }

  .short-name {
    margin-bottom: var(--spacing-025);
    font: var(--mobile-text-medium);
  }

  .full-name {
    font: var(--mobile-caption-1-regular);
  }

  .balance-crypto {
    margin-bottom: var(--spacing-025);
    font: var(--mobile-text-medium);
  }

  .balance-fiat {
    font: var(--mobile-caption-1-regular);
  }
}

.header-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-panel-title {
    font: var(--desktop-text-lg-semibold);
  }

  .header-panel-toggle {
    display: flex;
    gap: var(--spacing-450);
    align-items: center;
  }

  .header-panel-subtitle {
    font: var(--desktop-text-sm-medium);
  }
}

.empty-accounts {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  align-items: center;
  justify-content: center;

  margin-top: 20%;
  margin-bottom: 20%;

  .empty-accounts-text {
    font: var(--desktop-text-md-semibold);
    color: var(--text-tertiary);
    text-align: center;
  }
}

.small {
  .header-panel {
    order: 2;
    padding: var(--spacing-150);
    border-top: 0.5px solid var(--border-primary);
  }

  .header-panel-title {
    display: none;
  }

  .empty-accounts {
    .empty-accounts-text {
      width: 300px;
      font: var(--mobile-text-content-regular);
    }

    .empty-image {
      width: 200px;
    }
  }
}

.mobile {
  .header-panel {
    margin: var(--spacing-100);
    padding: var(--spacing-125);
    background-color: var(--background-secondary);
    border-radius: var(--border-radius-100);
  }

  .header-panel-title {
    display: none;
  }

  .header-panel-subtitle {
    font: var(--mobile-text-content-regular);
    color: var(--text-secondary);
  }

  .empty-accounts-text {
    width: 300px;
    font: var(--mobile-text-content-regular);
  }

  .empty-image {
    width: 200px;
  }

  .curency-header {
    display: none;
  }
}

/* stylelint-enable */
</style>
